@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@300;400&display=swap');

* {
  font-family: 'Zen Maru Gothic';
  box-sizing: border-box;
  font-weight: 400;
}
.form-outline {
  width: calc(100% - 32px);
  max-width: 960px;
  padding: 1px;
  margin: 40px auto;
  position: relative;
  border-radius: 8px;
  background-image: linear-gradient(to right, #FF2199, #FCF778,#FF2199);
  filter: drop-shadow(6px 10px 4px rgba(0, 0, 0, 0.25));

}
.form-outline.form-small {
  max-width: 460px;  
}
.form-outline.form-small .form-inline {
  padding: 30px 25px;
}
.form-inline {
  border-radius: 8px;
  background-color: #fff;
  justify-content: center;
  display: flex;
  height: calc(100% - 2px);
  padding: 38px;
  margin: 0;

}
.form-cancel .field {
  margin-top: 10px;
  display: block;
}
.plans {
  width: 50%;
  
}
.form {
  width: 50%;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  flex-flow: wrap; 
  padding: 0 20px;
  
}
.height50 {
  height: 50px;
}
.form .field {
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
}
.form .field-2 {
  width: calc(50% - 10px);
   
}
h1 {
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  margin-top: 32px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  color: #FF2199;
}
h2 {
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  color: #FF2199;
}
.input {
  margin: 0 auto;
  width: 100%;
  position: relative;

}

.input input + .placeholder, .input textarea + .placeholder {
  position: absolute;
  margin-left: 0;
    left: 10px;
    top: -24px;
    transition: all ease-in-out .3s;
}

.input input[value=""] + .placeholder,.input textarea:empty + .placeholder {
  position: absolute;
  margin-left: 0;
  left: 13px;
  top: 6px;
  cursor: text;
  pointer-events: none;
  color: rgba(0,0,0,.25);
  .icon-required:before {
    color: #ff2199;
  }
}
.input textarea {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  background-color: rgba(192, 192, 194, 0.1);
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 15px 10px;
}
.input input, .StripeElement {
  border: none;
  outline: none;
  display: block;
  width: 100%;
  background-color: rgba(192, 192, 194, 0.1);
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 15px 10px;
}
.form-placeholder .input input, .form-placeholder .input textarea {
  margin-top: 30px;
}
.form-placeholder .input input[value=""],.form-placeholder .input textarea:empty {
  margin-top: 10px;
}

.input .line, .StripeElement:after {
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #FF2199, #FCF778,#FF2199);
  bottom: 5px;
  left: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.StripeElement{
  position: relative;
  margin-top: 10px;
}
.StripeElement:after {
  content: '';
  bottom: 0px;
}
.input .line:before, .input .line:after {
  content: '';
  display: block;
  width: 4px;
  height: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: rotate(45deg);
  background-color: #FF2199;
  bottom: -1px;
  position: absolute;

}
.input .line:after {
  left: calc(100% - 4px);
}
.input+.input {
  margin-top: 40px;
} 
.btn:disabled {
  opacity: .6;
}
.btn {
  width: 100%;
  max-width: 168px;
  height: 40px;
  margin: 10px auto 0;
  background-image: linear-gradient(to right, #FF2199, #FCF778,#FF2199);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);
  border-radius: 30px;
  border: none;
  outline: none;
  overflow: hidden;
  position: relative;
  display: block;
  text-align: center;
}
.btn span {
  display: block;
  position: absolute;
  background: #fff;
  font-weight: 700;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  left: 1px;
  top: 1px;
  font-size: 14px;
  line-height: 36px;
  color: #FF2199;
  border-radius: 18px;
}
.btn:focus, .btn:active {
  outline: none;
}
.error {
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #FF2199;
}
h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  margin: 80px 20px 100px;
  color: #000000;
}
.m-l-1 {
  margin-left: 1rem;
}
.prices {
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.price-item {
  width: calc(100% - 40px);
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #444;
  border-radius: 8px;
  text-align: center;
  padding: 10px 20px;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  display: flex;
}
.price-item p,.price-item .btn {
  margin:  0;
}
.price-item.active {
  background-color: #FF2199;
}

h4 {
  color: #FF2199;
  font-size: 18px;
  margin: 0 0 10px 0;
  text-align: left;
}
.price-item p {
  text-align: left;
}
.note {
  font-size: 12px;
  line-height: 20px;
}
.price-item p.note {
  font-size: 11px;
  padding-right: 20px;
}
.price-item .btn {
  max-width: 120px;
}
.price-item.active h4, .price-item.active p {
  color: #fff;
}
.price-item.active .btn {
  opacity: .6;
  background-image: linear-gradient(to right, #FCF778, #FF2199,#FCF778);
}
.price-item.disabled{
  position: relative;
}
.price-item.disabled:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.1);
  top: 0;
  left: 0;
  z-index: 1;
}
.error {
  color: red;
  width: 100%;
  display: block;
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 10px;
}
body {
  background-color: rgba(0,0,0,.01);
}
.center {
  text-align: center;
}
.terms {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
}
.terms span {
  font-size: 12px;
  color: #444;
}
.terms a {
  text-decoration: none;
}
.privacy {
  font-size: 10px;
  text-align: center;
}
.pop_bg {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
  top: 0;
  left: 0;
}
.pop {
  position: fixed;
  top: 5%;
  width: calc(100% - 50px);
  max-width: 960px;
  height: 90%;
  border-radius: 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
}
.pop_body {
  border: 1px solid #545454;
  border-radius: 2px;
  overflow-y: auto;
  height: calc(100% - 150px);
}
.pop_body p {
  font-size: 12px;
  letter-spacing: 1.04px;
  padding: 15px;
}
.checkbox {
  font-size: 15px;
  margin:  15px auto 0;
}
.checkbox .link {
  color: #FF2199;
  text-decoration: underline;
  font-size: 15px;
}
.pop_header {
  display: flex;
  justify-content: center;
  position: relative;
}
.pop_close {
  position: absolute;
  right: 0px;
  top: 15px;
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;

}
.pop_close span {
  position: relative;
  display: block;
  height: 24px;
  width: 24px;
}
.pop_close span:after, .pop_close span:before {
  content: '';
  width: 2px;
  height: 24px;
  background-color: #000;
  position: absolute;
  left: 10px;
}
.pop_close span:before {
  transform: rotate(-45deg);
}
.pop_close span:after {
  transform: rotate(45deg);
}
.pop_header .title {
  font-size: 24px;
  letter-spacing: -0.015em;
  font-weight: 400;
  color: #000;

}

.icon-required:before {
  content: '*';
  font-size: 14px;
  margin-left: 2px;
  vertical-align: super;
  line-height: 14px;
  color: #FF2199;
}
.w-100 {
  width: 100%;
}
.link-renew {
  text-align: center;
  margin-top: 50px;
  max-width: 400px;
  color: #ff2199;
  display: block;
} 
.mnt {
  margin-top: 40px;
  margin-bottom: 20px;
}

.cancel_title{
  font-size: 36px;
  font-weight: 300;
  line-height: 52px;
  text-align: center;
  color: #000;
  margin-bottom: 40px;
}
.mt-30 {
  margin-top: 30px;
}
.d-block {
  display: block;
}
.text-left {
  text-align: left!important;
}
.cl-primary {
  color: #ff2199;
}
.text-underline {
  text-decoration: underline;
}
@media screen and (min-width: 801px) {
  .privacy {
    padding: 0 40px;
  }
  .plans {
    position: relative;
    padding-bottom: 40px;
  }
  .terms {
    height: 44px;
    align-items: center;
    padding-right: 40px;
  }

}
.expired {
  font-size: 18px;
  line-height: 26px;
  font-weight: 300;
  span {
    font-weight: 300;
  }
}
.cancel-confirm {
  text-align: left;
  padding: 20px 30px;
  background-color: #FEF9FC;
  label {
    color: #ff2199;
    font-size: 16px;
    line-height: 24px;
    &+p {
      font-size: 18px;
      line-height: 26px;
      margin-top: 0;
      padding-left: 30px;
    }
  }
}
@media screen and (max-width: 800px) {
  h1 {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 24px;
  }
  .form-outline {
   max-width: 460px;

  }
  .form-inline {
    flex-direction: column;
    padding: 20px 16px 40px;
  }
  .form {
    padding: 0;
  }
  .plans {
    width: 100%;
    margin-bottom: 30px;


  }
  .form {
    width: 100%;
  }
  .price-item {
    width: 100%;
  }
}
.modal {
  * {
    box-sizing: border-box;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  &-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
  }
  &-content {
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    // padding: 20px;
    top: 50%;
    left: 50%;
    max-width: 350px;

    transform: translate(-50%, -50%);
    position: relative;
    z-index: 1;
    box-shadow: 0 14px 14px rgba(0,0,0,.5);
  }
  &-header {
    padding: 5px 20px;
    h5 {
      margin: 0;
      font-weight: 700;
    }
  }
  &-body {
    padding: 20px;
  }
  &-footer {
    padding-bottom: 20px;
  }
  
}
.bg-danger {
  background-color: #ff2199;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}
.agency {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 500;
  color: #ff2199;
  display: block;
  text-align: center;
  margin-top: 60px;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
  span {
    font-size: 28px;
    font-weight: 700;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 800px) {
      font-size: 16px;
    }
  }
}